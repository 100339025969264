import React, { useState } from 'react'
import Title from '../components/Title'
import InputArea from '../features/contact/InputArea';
import { useForm } from 'react-hook-form';
import Confirm from '../features/contact/Confirm';
import MiddleMessage from '../features/contact/MiddleMessage';
import Successed from '../features/contact/Successed';
import topImg from '../asset/img/top/contact.png'

interface FormData {
    name: string;
    company: string;
    email: string;
    phone: string;
    content: string;
}

const Contact = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>();
    const [isConfirm, setIsConfirm] = useState(false);
    const [formData, setFormData] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);

    // 確認画面移動
    const onSubmit = (data : any) => {
        setFormData(data);
        setIsConfirm(true); // 確認画面に切り替え
      };

    // 確認画面から戻る
    const handleBack = () => {
        setIsConfirm(false); // 修正画面に戻る
        // reset(formData); // 入力内容をリセット
    };

    // 送信処理
    const handleFinalSubmit = async () => {
        if (!formData) return;

        try {
            const response = await fetch("https://ssgform.com/s/8lzOeXbDFZhB", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                reset(); // フォームをリセット
                setIsConfirm(false);
                setIsSubmit(true);
            } else {
                alert("送信に失敗しました。お手数ですが、もう一度お試しいただくか、メールにてご連絡ください。contact@movken.jp");
            }
        } catch (error) {
            alert("エラーが発生しました。ネットワークを確認してください。お手数ですが、もう一度お試しいただくか、メールにてご連絡ください。contact@movken.jp");
        }
    };
  return (
    <div>
        
        {!isSubmit ? <>
            <Title
            pageTitle='Contact'
            height='500px'
            topImg={topImg}
        />
            {!isConfirm ? 
            <>
            
                <MiddleMessage />
                    <InputArea
                    register={register}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    reset={reset}
                    onSubmit={onSubmit}
                />
            </>
            :
            
            <Confirm 
                formData={formData!}
                handleBack={handleBack}
                handleFinalSubmit={handleFinalSubmit}
            />}
        </>: 
        <Successed />}
    <div style={{
        margin: "0 0 100px"
    }}/>
    </div>
  )
}

export default Contact