import React from 'react';
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */


interface Work {
    id: number;
    title: string;
    en_title: string;
    thumbnail: string;
    tag: string[];
    body: string;
    credit: string;
    media: MediaItem[];
    createdAt: string;
    updatedAt: string;
}

interface MediaItem {
    type: string; // "vimeo" または "image" を想定
    url?: string; // Vimeo の場合の URL
    image?: string; // 画像の場合のパス
  }
  
  interface InfoContainerProps {
    work: Work; // 修正: work プロパティを型指定
}


const InfoContainer: React.FC<InfoContainerProps> = ({
        work
    }) => {
    return (
        <div css={infoContainer}>
            <div css={tagContainer}>
                {work.tag.map((tag, index) => (
                    <span key={index} css={tagItem}>{tag}</span> 
                ))}
            </div>
            
            <h1 css={title}>{work.title}</h1>
            <h2 css={subTitle}>{work.en_title}</h2>
        </div>
    );
};
export default InfoContainer;

const infoContainer = css`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    gap: 20px;

    h1 {
        font-size: 36px;
        font-weight: bold;
    }

    h2 {
        font-size: 14px;
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 24px;

        }

        h2 {
            font-size: 16px;
        }
    }
`;


const tagContainer = css`
  display: flex;
  gap: 8px; /* タグ間の隙間 */
`;

const tagItem = css`
  position: relative;
  font-size: 16px;
  opacity: 0.5;
  font-family: "Be Vietnam Pro";
  
  /* タグ間に区切り線 | を追加 */
  &::after {
    content: '|';
    margin-left: 8px; /* | とタグの間隔 */
  }

  /* 最後のタグには区切り線を表示しない */
  &:last-child::after {
    content: '';
  }
`;

const title = css`
    font-family: "Be Vietnam Pro";
`;

const subTitle = css`
    font-family: "Be Vietnam Pro";
`;