import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from 'react-router-dom';

const TopButton = () => {

    const Container = css`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        /* padding-top: 100px; */
        padding: 100px 0;
        cursor: pointer;
    `

    const fontCss = css`
        color: #F6F6F6;
        font-family: "Be Vietnam Pro";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    `
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/`);
    };

  return (
    <div css = {Container} onClick={handleClick}>
        <div css = {fontCss}>トップへ</div>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none"><path d="M0 9L0 0L12 4.73684L0 9Z" fill="#F6F6F6"/></svg>
    </div>
  )
}

export default TopButton