/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Title from '../components/Title';
import Memberpicture from '../features/member/Memberpicture';
import topImg from '../asset/img/top/member.png'

const Member = () => {

    const Container = css`
        margin: 0 auto 100px; 
    `

    return (
        <div css={Container}>
            <Title 
                pageTitle="Member"
                height='685px' 
                topImg={topImg}
            />
            <Memberpicture />
        </div>
    );
};

export default Member;
