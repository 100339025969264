import React, { useState } from 'react';
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { motion } from "framer-motion"; // framer-motionをインポート
import Hayato from '../../asset/img/Hayato.png';
import HayatoAlt from '../../asset/img/HayatoAlt.png';
import Kaito from '../../asset/img/Kaito.png';
import KaitoAlt from '../../asset/img/KaitoAlt.png';
import Makigon from '../../asset/img/Makigon.png';
import MakigonAlt from '../../asset/img/MakigonAlt.png';
import Sakaken from '../../asset/img/Sakaken.png';
import SakakenAlt from '../../asset/img/SakakenAlt.png';
import Sakura from '../../asset/img/Sakura.png';
import SakuraAlt from '../../asset/img/SakuraAlt.png';
import Shun from '../../asset/img/Shun.png';
import ShunAlt from '../../asset/img/ShunAlt.png';
import Syouta from '../../asset/img/Syouta.png';
import SyoutaAlt from '../../asset/img/SyoutaAlt.png';
import Koto from '../../asset/img/Koto.png';
import KotoAlt from '../../asset/img/KotoAlt.png';
import Instagram from '../../features/Instagram';
import Twitter from '../../features/Twitter';

// Emotion CSSスタイル
const Pictures = css`   
  width: 250px;
  height: 418.9px;
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.30);
  cursor: pointer;
`;

const Picturecontainer = css`
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
`;

const Membercontainer = css`
  max-width: 1600px;
  display: grid;
  //grid-template-columns: repeat(4, 1fr); /* デフォルトで4列表示 */
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  margin: 0 auto;
  width: 85%;

  @media (max-width: 1250px) {
    grid-template-columns: repeat(3, 1fr); 
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr); 
  }



  @media (max-width: 768px) {
    grid-template-columns: 1fr; 
  }
  
`;

const snsContainer = css`
  display: flex;
  justify-content: center;
  margin: 20px;
  gap: 10px;
`;

// 画像タイプの定義
type ImagesType = {
  Syouta: string;
  Koto: string;
  Sakaken: string;
  Makigon: string;
  Kaito: string;
  Hayato: string;
  Sakura: string;
  Shun: string;
};

const Memberpicture = () => {
  // 画像の状態管理
  const [images, setImages] = useState<ImagesType>({
    Syouta: Syouta,
    Koto: Koto,
    Sakaken: Sakaken,
    Makigon: Makigon,
    Kaito: Kaito,
    Hayato: Hayato,
    Sakura: Sakura,
    Shun: Shun,
  });

  // 画像切り替え関数
  const toggleImage = (
    key: keyof ImagesType, // keyの型指定
    primary: string,
    alternate: string
  ) => {
    setImages((prev) => ({
      ...prev,
      [key]: prev[key] === primary ? alternate : primary,
    }));
  };

  // レンダリング
  return (
    <div css={Membercontainer}>
      {/* 画像リスト */}
      <div css={Picturecontainer}>
        <motion.div
          key={images.Syouta} // 画像が変更されるたびにkeyを変更してアニメーションをトリガー
          initial={{ opacity: 0, rotateY: 90 }} // 初期状態で回転
          animate={{ opacity: 1, rotateY: 0 }} // アニメーションで回転を解除
          exit={{ opacity: 0, rotateY: -90 }} // 退場時に回転
          transition={{ duration: 0.8 }} // アニメーションの持続時間
          style={{
            margin: "0 auto",
          }} 
        >
          <img
            src={images.Syouta}
            css={Pictures}
            alt="Syouta"
            onClick={() => toggleImage("Syouta", Syouta, SyoutaAlt)}
          />
        </motion.div>
        <div css={snsContainer}>
          <Instagram url="https://www.instagram.com/shotaroev1125/" />
        </div>
      </div>

      <div css={Picturecontainer}>
        <motion.div
          key={images.Koto}
          initial={{ opacity: 0, rotateY: 90 }}
          animate={{ opacity: 1, rotateY: 0 }}
          exit={{ opacity: 0, rotateY: -90 }}
          transition={{ duration: 0.8 }}
          style={{
            margin: "0 auto",
          }}
        >
          <img
            src={images.Koto}
            css={Pictures}
            alt="Koto"
            onClick={() => toggleImage("Koto", Koto, KotoAlt)}
          />
        </motion.div>
        <div css={snsContainer}>
          <Instagram url="https://www.instagram.com/koto.k_999/" />
        </div>
      </div>

      <div css={Picturecontainer}>
        <motion.div
          key={images.Sakaken}
          initial={{ opacity: 0, rotateY: 90 }}
          animate={{ opacity: 1, rotateY: 0 }}
          exit={{ opacity: 0, rotateY: -90 }}
          transition={{ duration: 0.8 }}
          style={{
            margin: "0 auto",
          }}
        >
          <img
            src={images.Sakaken}
            css={Pictures}
            alt="Sakaken"
            onClick={() => toggleImage("Sakaken", Sakaken, SakakenAlt)}
          />
        </motion.div>
        <div css={snsContainer}>
          <Instagram url="https://www.instagram.com/saka1_0907/" />
          <Twitter url="https://x.com/Saka1_0907" />
        </div>
      </div>

      <div css={Picturecontainer}>
        <motion.div
          key={images.Makigon}
          initial={{ opacity: 0, rotateY: 90 }}
          animate={{ opacity: 1, rotateY: 0 }}
          exit={{ opacity: 0, rotateY: -90 }}
          transition={{ duration: 0.8 }}
          style={{
            margin: "0 auto",
          }}
        >
          <img
            src={images.Makigon}
            css={Pictures}
            alt="Makigon"
            onClick={() => toggleImage("Makigon", Makigon, MakigonAlt)}
          />
        </motion.div>
        <div css={snsContainer}>
          <Instagram url="https://www.instagram.com/maki_yugo7517/" />
          <Twitter url="https://x.com/gongon_makigon" />
        </div>
      </div>

      <div css={Picturecontainer}>
        <motion.div
          key={images.Kaito}
          initial={{ opacity: 0, rotateY: 90 }}
          animate={{ opacity: 1, rotateY: 0 }}
          exit={{ opacity: 0, rotateY: -90 }}
          transition={{ duration: 0.8 }}
          style={{
            margin: "0 auto",
          }}
        >
          <img
            src={images.Kaito}
            css={Pictures}
            alt="Kaito"
            onClick={() => toggleImage("Kaito", Kaito, KaitoAlt)}
          />
        </motion.div>
        <div css={snsContainer}>
          <Instagram url="https://www.instagram.com/kaito_matsuda_/" />
          <Twitter url="https://x.com/um1h1t0" />
        </div>
      </div>

      <div css={Picturecontainer}>
        <motion.div
          key={images.Hayato}
          initial={{ opacity: 0, rotateY: 90 }}
          animate={{ opacity: 1, rotateY: 0 }}
          exit={{ opacity: 0, rotateY: -90 }}
          transition={{ duration: 0.8 }}
          style={{
            margin: "0 auto",
          }}
        >
          <img
            src={images.Hayato}
            css={Pictures}
            alt="Hayato"
            onClick={() => toggleImage("Hayato", Hayato, HayatoAlt)}
          />
        </motion.div>
        <div css={snsContainer}>
          <Instagram url="https://www.instagram.com/m_matsu3/" />
        </div>
      </div>

      <div css={Picturecontainer}>
        <motion.div
          key={images.Sakura}
          initial={{ opacity: 0, rotateY: 90 }}
          animate={{ opacity: 1, rotateY: 0 }}
          exit={{ opacity: 0, rotateY: -90 }}
          transition={{ duration: 0.8 }}
          style={{
            margin: "0 auto",
          }}
        >
          <img
            src={images.Sakura}
            css={Pictures}
            alt="Sakura"
            onClick={() => toggleImage("Sakura", Sakura, SakuraAlt)}
          />
        </motion.div>
        <div css={snsContainer}>
          <Instagram url="https://www.instagram.com/sakura_o729/" />
          <Twitter url="https://x.com/SakuOfficial729" />
        </div>
      </div>

      <div css={Picturecontainer}>
        <motion.div
          key={images.Shun}
          initial={{ opacity: 0, rotateY: 90 }}
          animate={{ opacity: 1, rotateY: 0 }}
          exit={{ opacity: 0, rotateY: -90 }}
          transition={{ duration: 0.8 }}
          style={{
            margin: "0 auto",
          }}
        >
          <img
            src={images.Shun}
            css={Pictures}
            alt="Shun"
            onClick={() => toggleImage("Shun", Shun, ShunAlt)}
          />
        </motion.div>
        <div css={snsContainer}>
          <Instagram url="https://www.instagram.com/syuuuu0201/" />
        </div>
      </div>
    </div>
  );
};

export default Memberpicture;
