import React from 'react'
import Title from '../components/Title'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AboutTop from '../features/about/AboutTop';
import MovkenOrigin from '../features/about/MovkenOrigin';
import { Vision } from '../features/about/ Vision';
import topImg from '../asset/img/top/about.png'

const About = () => {

    const Container = css`
        width: 80%;
        margin: 0 auto;
        padding-bottom: 10%;
        max-width: 1600px;
        @media screen and (max-width:652px){
            padding-bottom: 18%;
        }
    `


  return (
    <div>
        <Title 
            pageTitle='About'
            topImg={topImg}
            height='530px'
        / >
        <div css = {Container}>
            <AboutTop / >
            <MovkenOrigin />
            <Vision />
        </div>
    </div>
  )
}

export default About