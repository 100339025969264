import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

interface InputProps {
    label: string;
    placeholder: string;
    required: boolean;
    register: any;
    error?: any;
}

const InputBox = ({
    label,
    placeholder,
    required,
    register,
    error,
}: InputProps ) => {
  return (
    <div css={wapper}>
        <div css={flex}>
            <label css={labelCss}>{label}{required ? <span css={requiredCss}>*</span> : "" }</label>
            {error && <p css={errorCss}>{error.message}</p>}
        </div>
        <input type="text" css={inputCss} placeholder={placeholder} {...register} />
        
    </div>
  )
}


const wapper = css`
    margin-bottom: 20px;
`;
const flex = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    @media screen and (max-width: 1000px){
        font-size: 12px;
    }
    @media screen and (max-width: 652px){
        flex-direction: column;
        gap:4px
    }
    
    `;
const inputCss = css`
        width: 100%;
        padding: 8px 0px;
        font-size: 16px;
        background-color: #3C3C3C;
        color: #F6F6F6;
        border: none;
        text-indent: 14px;
`;

const labelCss = css`
    color: #F6F6F6;
`;


const requiredCss = css`
    color: #FF1C1C;
`;

const errorCss = css`
    color: #FF1C1C;
    @media screen and (max-width: 1000px){
        font-size: 10px;
    }
`;
export default InputBox