import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const Underline = () => {

    const Wrapper = css`
        margin: 10px 0;
        display: flex;
        align-items: center;
    `

  return (
    <div css = {Wrapper}>
        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="2" viewBox="0 0 45 2" fill="none"><path d="M0 1H45" stroke="#F6F6F6" />
        </svg>
    </div>
  )
}

export default Underline