import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const Title = ({
    pageTitle = "タイトル",
    fontColor = "#FFF",
    height = "700px",
    topImg = ""
}) => {

    const Container = css`
        height: ${height};
        color: ${fontColor};
        text-align: center;
        font-size: 128px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @media screen and (max-width: 1000px) {
            font-size: 100px;
        } 
        @media screen and (max-width: 652px) {
            font-size: 60px;
        } 
    `

    const Box = css`
        height: 402px;
    `

    const Top = css`
        position: relative;
        top: -95px;
        letter-spacing: -3.84px;
        font-family: "Be Vietnam Pro";
        @media screen and (max-width: 1000px) {
            top: -72px;
        } 
        @media screen and (max-width: 652px) {
            top: -43px;
        } 
    `
    const imgCss = css`
        height: 100%;
        width: 100%;
        object-fit: cover;

    `;

    return (
        <div css={Container}>
            <div css={Box}>
                <img css={imgCss} src={topImg} alt="" />
            </div>
            <p css = {Top}>{pageTitle}</p>
        </div>
  )
}

export default Title