import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const PricacyTitle = () => {

    const pageTitle = css`
        width: 100%;
        width: 90%;
        color: var(--white, #F6F6F6);
        font-family: "Be Vietnam Pro";
        font-size: 100px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
        margin: 120px auto 60px;
        @media screen and (max-width: 1000px) {
          font-size: 50px;
        }
    `

  return (
    <div>
        <p css={pageTitle}>Privacy Policy</p>
    </div>
  )
}

export default PricacyTitle