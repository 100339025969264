import React, { useState, useEffect } from 'react';
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Movicon from '../asset/img/logo.png';
import { Link } from 'react-router-dom';

const Icon = css`
  width: 70px; /* アイコンの大きさを調整 */
  @media screen and (max-width:1000px){
    width: 50px;
    margin: 0 30px; 
  }
`;

const headerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height:90px;
  position: relative;
  @media screen and (max-width: 1000px){
    height: 0;
  }
`;

const ulStyle = css`
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  color: #F5F5F5;
  text-align: center;
  position: fixed;
  background-color: #212024;
  width: 100%;
  height: 90px;
  padding-left: 40px;
  @media screen and (max-width: 1000px) {
    display: none; /* ハンバーガーメニュー時は非表示 */
  }
`;

const fontFamily = css`
    font-family: "Be Vietnam Pro";
`;

const liStyle = css`
  list-style: none;
  padding: 0 50px;
  font-size: 20px;
  font-weight: 600;
  color: #FFFFFF;
  text-decoration: none;

  @media screen and (max-width: 1000px) {
    padding: 0;
    font-size: 16px;
  }
`;

const humburgerButton = css`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  background: none;
  border: none;
  cursor: pointer;

  & div {
    width: 100%;
    height: 3px;
    background: #fff;
  }

  @media screen and (max-width: 1000px) {
    display: flex;
    position: absolute;
    right: 20px;
    width: 50px;
    height: 20px;
  }
`;

const mobileMenu = (isOpen: boolean) => css`
  display: ${isOpen ? 'flex' : 'none'};
  flex-direction: column;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #212024;
  padding: 10px 0px;
  z-index: 1;
  text-align: end;
  & a {
    padding: 10px 26px;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
  }
`;

const mobileHeader = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #212024;
  z-index: 1000;
  height: 60px;

  @media screen and (min-width: 1000px) {
    display: none;
  }
`;

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // ウィンドウサイズ変更を監視してmenuOpenをリセット
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setMenuOpen(false); // 1000pxを超えたらメニューを閉じる
      }
    };

    window.addEventListener('resize', handleResize);

    // クリーンアップ処理
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <header css={headerStyle}>
      {/* 通常のヘッダー */}
      <ul css={ulStyle}>
        <Link to={"/about"} css={[liStyle, fontFamily]}>About</Link>
        <Link to={"/works"} css={[liStyle, fontFamily]}>Works</Link>
        <Link to={"/"} css={[liStyle, fontFamily]}><img src={Movicon} css={Icon} alt='' /></Link>
        <Link to={"/member"} css={[liStyle, fontFamily]}>Member</Link>
        <Link to={"/contact"} css={[liStyle, fontFamily]}>Contact</Link>
      </ul>

      {/* モバイル用ヘッダー */}
      <div css={mobileHeader}>
        <Link to={"/"}>
          <img src={Movicon} css={Icon} alt="Logo" />
        </Link>
        <button css={humburgerButton} onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </button>
      </div>

      {/* ハンバーガーメニュー */}
      <nav css={mobileMenu(menuOpen)}>
        <Link to={"/about"} css={fontFamily} onClick={() => setMenuOpen(false)}>About</Link>
        <Link to={"/works"}  css={fontFamily} onClick={() => setMenuOpen(false)}>Works</Link>
        <Link to={"/member"}  css={fontFamily} onClick={() => setMenuOpen(false)}>Member</Link>
        <Link to={"/contact"}  css={fontFamily} onClick={() => setMenuOpen(false)}>Contact</Link>
      </nav>
    </header>
  );
};

export default Header;
