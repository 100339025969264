import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import movLogo from '../../asset/img/about/movLogo3.png'
import movSymbol from '../../asset/img/about/symbol.png'
import movLogotype from '../../asset/img/about/logotype.png'

const MovkenOrigin = () => {

    const Wrapper = css`
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    ` 

    const pngWrapper = css`
        margin: 75px 0;
        width: 100%;
        display: flex;
        justify-content: space-around;

        @media screen and (max-width:1130px){
            justify-content: center;
        }
        @media screen and (max-width:1000px){
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 120px;
        }
    `

    const logoCss = css`
        width: 300px;
        object-fit: contain;
    `

    const symbolCss = css`
        width: 300px;
        object-fit: contain;
    `

    const logotypeCss = css`
        width: 300px;
        object-fit: contain;
    `

    const logoTitle = css`
        width: 160px;
        text-align: center;
        background: #F6F6F6;
        font-family: "Noto Sans JP";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media screen and (max-width:1000px){
        width: 140px;
        font-size: 18px;
        }
    `

    const explainTextCss = css`
        color: #FFF;
        font-family: "Noto Sans JP";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        margin-bottom: 8%;
        @media screen and (max-width: 1000px){
            font-size: 16px;
            line-height: 30px;
        }
        @media screen and (max-width:652px){
            font-size: 12px;
            line-height: 25px;
        }
    `

  return (
    <div>
        <div css = {Wrapper}>
            <div css = {logoTitle}>ロゴの成り立ち</div>
            <div css={pngWrapper}>
                <img src={movLogo} alt="ロゴ" css = {logoCss}/>
                <img src={movSymbol} alt="ロゴ" css = {symbolCss}/>
                <img src={movLogotype} alt="ロゴ" css = {logotypeCss}/>
            </div>
            <p css = {explainTextCss}>カメレオンは、行動力の柔軟性、高い適応力の暗示、変容と多様性の象徴とされている。<br />
            個々の強みを活かしながらも変化を恐れず、多様な視点を取り入れ進化し続けるチームでありたいという願いを込めてモチーフにした。カメレオンの目のフィルムは、”mov研”のmovieから着想を得ている。</p>
        </div>
    </div>
  )
}

export default MovkenOrigin