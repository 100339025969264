import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
/** @jsxImportSource @emotion/react */
import { Global, css } from "@emotion/react";
import Works from './pages/Works';
import NotFound from './pages/NotFound';
import About from './pages/About';
import WorkDetail from './pages/WorkDetail';
import Header from './components/Header';
import Footer from './components/Footer';
import emotionReset from "emotion-reset";

import Member from './pages/Member';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Contact from './pages/Contact';
import Successed from './features/contact/Successed';
import ScrollTop from './components/ScrollTop';

function App () {
    const global = css`
        @import url('https://fonts.googleapis.com/css2?family=Aldrich&family=Noto+Sans+JP:wght@100..900&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


        ${emotionReset}
        body {
        background: #212024;
        margin: 0;
        }
        * {
            font-family: "Noto Sans JP";
        }
        h1, h2, h3, h4, h5, h6, p, a, li, span, div, input, textarea {
            font-family: "Noto Sans JP";
        }
        button{
            transition: all 0.3s;
            :hover {
                opacity: 0.8;
            }
        }

    `;
  return (
    <div className="App">
        <Global styles={global}/>
        <ScrollTop />
        <Header />
        <Routes>
            <Route path="*" element={ <NotFound /> } />
            <Route path="/" element={ <Home /> } />
            <Route path="/about" element={ <About /> } />
            <Route path="/works" element={ <Works /> } />
            <Route path="/works/:slug" element={<WorkDetail />} />
            <Route path="/member" element={ <Member /> } />
            <Route path="/contact" element={ <Contact /> } />
            <Route path="/privacy" element={ <PrivacyPolicy /> } />
            <Route path="/confirm" element={ <Successed /> } />
            
        </Routes>
        <Footer />

    </div>
  );
}

export default App