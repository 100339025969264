import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from 'react-router-dom';


interface BoxProps {
    id: number;
    slug: string;
    title: string;
    en_title: string;
    thumbnail: string;
    tag: string[];
    body: string;
    credit: string;
    media: string;
    createdAt: string;
    updatedAt: string;
}

const Box= ({
    id,
    slug,
    title,
    en_title,
    thumbnail,
    tag,
    body,
    credit,
    media,
    createdAt,
    updatedAt
} : BoxProps ) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/works/${slug}`);
    };

    const imgBaseUrl = process.env.REACT_APP_IMG_URL!;

    return (
        // divを押したら詳細ページに飛ぶようにする
            <div css={wapper} onClick={handleClick}>
                <img css={imgeCss} src={imgBaseUrl + thumbnail} alt='サムネイル'/>
                <div css={tagContainer}>
                    <span css={horizontalLine}>ー</span> 
                    {tag.map((tagp, index) => (
                        <span key={index} css={tagItem}>{tagp}</span> 
                    ))
                    }</div>
                <div css={titleCss}>{title}</div>
                <div css={enTitleCss}>{en_title}</div>
            </div>
    )
}




const tagContainer = css`
    display: flex;
    color: #FFF;
    margin: 10px 0 6px;
`;

const horizontalLine = css`
  content: "ー"; 
  font-size: 16px;
  opacity: 0.5;
  margin-right: 8px; 
`;

const tagItem = css`
  /* position: relative; */
  font-size: 16px;
  opacity: 0.5;
  font-family: "Be Vietnam Pro";

  /* タグ間に縦線を追加 */
  &:not(:last-child)::after {
    content: "|";
    margin: 0 2px; 
    color: #ffffff;
    opacity: 0.5; 
  }
`;


const wapper = css`
    margin: 30px;
    cursor: pointer;
    font-family: 'Noto Sans JP', sans-serif;
    @media screen and (max-width: 1000px) {
        margin: 0 30px;
        padding-bottom: 60px;
    }
    @media screen and (max-width: 652px) {
        margin: 0 40px;
        padding-bottom: 60px;
    }
`;

const imgeCss = css`
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
`;


const titleCss = css`
    color: #FFFFFF;
    /* font-size: 24px; */
    font-size: 20px;
    line-height: normal;
    font-weight: 700;
`;


const enTitleCss = css`
    color: #FFFFFF;
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    font-family: "Be Vietnam Pro";
`;
export default Box