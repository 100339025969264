import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Underline from './Underline';

const Subtitle = ({
    stringValue = "ここに文字が入ります"
}
) => {

    const Wrapper = css`

    `

    const fontSet = css`
        color: #FFF;
        font-family: "Be Vietnam Pro";
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -3.2px;
        @media screen and (max-width:1000px){
            font-size: 46px;
        }
    `
  return (
    <div css={Wrapper}>
      <p css = {fontSet}>{stringValue}</p>
      <Underline />
    </div>
  )
}

export default Subtitle