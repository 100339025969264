import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const PrivacyMessage = () => {

    const Wrapper = css`
      width: 95%;
      margin: 0 auto;
    `

    const Date = css`
        text-align: right;
        color: var(--white, #F6F6F6);
        text-align: right;
        font-family: "Noto Sans JP";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
        padding: 10px 0;
    `

    const Message = css`
        color: var(--white, #F6F6F6);
        text-align: center;
        font-family: "Noto Sans JP";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 200%; /* 32px */
        padding: 20px 0;
    `

  return (
    <div css = {Wrapper}>
        <p css={Date}>制定日：2024年12月1日</p>
        <p css = {Message}>mov研（以下、「当チーム」といいます。）は、本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）における、ユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。</p>
    </div>
  )
}

export default PrivacyMessage