import React, { useEffect, useState } from 'react'
import Title from '../components/Title'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Box from '../features/works/Box';

import topImg from '../asset/img/top/works.png'


interface Work {
    id: number;
    slug: string;
    title: string;
    en_title: string;
    thumbnail: string;
    tag: string[];
    body: string;
    credit: string;
    media: string;
    createdAt: string;
    updatedAt: string;
}

const Works = () => {
    // jsonからデータを取得する
    // https://dev.movken.jp/panel/works
    const [works, setWorks] = useState([]);
    useEffect(() => {
        fetch('https://panel.movken.jp/web/works')
        .then(response => response.json())
        .then(data => {
            setWorks(data)
            console.log(data);
        })
    }, []);
  return (
    <div>
        <Title
            pageTitle='Works'
            height='600px'
            topImg={topImg}
        />
        <div css={items}>
            {works.map((work: Work) => (
                <Box
                    id={work.id}
                    slug={work.slug}
                    title={work.title}
                    en_title={work.en_title}
                    thumbnail={work.thumbnail}
                    tag={work.tag}
                    body={work.body}
                    credit={work.credit}
                    media={work.media}
                    createdAt={work.createdAt}
                    updatedAt={work.updatedAt}
                />
            ))}
        </div>
    </div>
  )
}

const items = css`
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 横に2列並べる */
    max-width: 1200px;
    width: 80%;
    margin: 0 auto 100px;
    @media screen and (max-width: 1000px) {
        display: block;
        width: 100%;
    }
`

export default Works