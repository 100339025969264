import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Subtitle from './Subtitle'

const AboutTop = () => {

    const textWrapper =css`
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 40px;
        @media screen and (max-width:1000px){
            font-size: 16px;
            line-height: 30px;
        }
        @media screen and (max-width:652px){
            font-size: 12px;
            line-height: 25px;
        }
    `

    const movAboutCss = css`
        padding-bottom: 5%;
    `

  return (
    <div>
        <Subtitle 
            stringValue='About'
        />
        <div css={textWrapper}>
            <p css={movAboutCss}>mov研 (読み：もぶけん) は、2023年に設立したクリエイターチームである。<br/>
                メンバーの個性を活かし、映像・撮影・3DCG・Web・グラフィックデザインなどの幅広いコンテンツ制作を行なっている。</p>
        </div>
    </div>
  )
}

export default AboutTop