import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
interface MediaContainerProps {
    work: Work;
    mainMedia: MediaItem | null;
    imgBaseUrl: string;
    setMainMedia: (item: MediaItem) => void;
}

interface Work {
    id: number;
    title: string;
    en_title: string;
    thumbnail: string;
    tag: string[];
    body: string;
    credit: string;
    media: MediaItem[];
    createdAt: string;
    updatedAt: string;
}
interface MediaItem {
    type: string; // "vimeo" または "image" を想定
    url?: string; // Vimeo の場合の URL
    image?: string; // 画像の場合のパス
  }

const MediaContainer: React.FC<MediaContainerProps> = ({
     work,
     mainMedia,
     imgBaseUrl,
     setMainMedia,
    }) => {
    return (
        <div>
                <div css={mainContainer}>
                {mainMedia?.type === "image" && (
                    <img
                        src={imgBaseUrl + mainMedia.image}
                        alt="main-media"
                        css={mainImageStyle}
                    />
                )}
                {mainMedia?.type === "vimeo" && mainMedia.url && (
                    <iframe
                        src={mainMedia.url}
                        css={iframeStyle}
                        title="main-vimeo"
                        allowFullScreen
                    />

                )}
            </div>

            <div css={mediaContainer}>
                {work.media.map((item, index) => (
                    <div
                        key={index}
                        css={gridItem}
                        onClick={() => setMainMedia(item)} 
                    >
                        <div css={vimeoThumbnailWrapper}
                        style={{
                            opacity: mainMedia === item ? 0.3 : 1,
                        }}>
                            {item.type === "vimeo" && ( <div css={playButtonStyle}>▶</div> )}
                            <img
                                src={imgBaseUrl! + item.image}
                                alt={`image-${index}`}
                                css={imageStyle}
                            />
                        </div>
                    </div>
                ))}
            </div>
    </div>
    );
};
const mainContainer = css`
    aspect-ratio: 16 / 9;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
    /* height: 100%; */
`;
const mainImageStyle = css`
    width: 100%;
    object-fit: contain;
    height: 100%;
    margin: 0 auto;
`;

const iframeStyle = css`
    width: 100%;
    height: 100%;
    border: none;
    background: black;
`;
const mediaContainer = css`
    max-width: 800px;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4列 */
    grid-template-rows: auto;
    gap: 10px; /* アイテム間の隙間 */
    width: 95%;
    margin: 40px auto;
`;

const gridItem = css`
    background-color: #222; /* 背景色を設定 */
    border-radius: 8px;
    overflow: hidden; /* 画像がはみ出さないようにする */
`;
const vimeoThumbnailWrapper = css`
    position: relative; /* 再生ボタンを画像上に配置するため */
    cursor: pointer; /* サムネイルをクリック可能に見せる */
`;

const playButtonStyle = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* ボタンを中央に配置 */
    background-color: rgba(0, 0, 0, 0.6); /* 半透明の背景色 */
    border-radius: 50%; /* 丸いボタン */
    color: white; /* ボタンの文字色 */
    padding: 10px 10px; /* ボタンのサイズ調整 */
    font-size: 20px; /* ボタンの文字サイズ */
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const imageStyle = css`
    width: 100%;
    height: auto; /* アスペクト比を維持 */
    display: block;
`;

export default MediaContainer;