import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Copylight from '../features/Copylight';
import Instagram from '../features/Instagram';
import Twitter from '../features/Twitter';

const Container = css`
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-contents: flex-end;
  position: fixed;
  bottom: 30px;
  right: 60px;
  margin: 0 auto;
  user-select: none;
  @media screen and (max-width:1000px){
    right: 30px;
  }
  @media screen and (max-width:652px){
    right: 20px;
  }
`

const Footer = () => {
  return (
    <div css={Container}>
      <Instagram 
        url='https://www.instagram.com/movken/'
      />
      <Twitter 
       url ='https://x.com/movken_official'
      />
      <Copylight />
    </div>
  )
}

export default Footer