import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";


const MiddleMessage = () => {

  const Container = css`
    color: #FFF;
    text-align: center;
    font-family: "Noto Sans JP";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media screen and (max-width: 652px){
      width: 80%;
      margin: 0 auto;
      font-size: 12px;
    }
  `

  const Need = css `
    display: flex;
    justify-content: center;
    margin: 46px 0;
    @media screen and (max-width: 652px){
      margin: 30px 0;
    }
  `

  const asterisk = css`
    color: #FF1C1C;
    font-family: "Noto Sans JP";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `

  return (
    <div css={Container}>
      <p>ご依頼、ご相談は下記フォームからお気軽にお問い合わせください。</p>
      <div css = {Need}><p css={asterisk}>*</p>は必須項目</div>
    </div>
  )
}

export default MiddleMessage