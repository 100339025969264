/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import InputBox from './InputBox';
import InputBoxText from './InputBoxText';
interface InputProps {
    register: any;
    handleSubmit: any;
    errors: any;
    reset: any;
    // onSubmit?: any;
    onSubmit: any;
}

const InputArea = ({
    register,
    handleSubmit,
    errors,
    reset,
    // onSubmit,
    onSubmit,
} : InputProps) => {
    


    return (
        <form css={wrapper} onSubmit={handleSubmit(onSubmit)}>
            
            <InputBox 
                label="氏名"
                placeholder="山田 太郎"
                required={true}
                register={register("name", { required: "氏名は必須です。" })}
                error={errors.name}
            />
            <InputBox 
                label="会社名"
                placeholder="山田株式会社"
                required={false}
                register={register("company")}
            />
            <InputBox 
                label="メールアドレス(半角英数)"
                placeholder="example@mail.com"
                required={true}
                register={register("email", {
                    required: "メールアドレスは必須です。",
                    pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "正しいメールアドレスを入力してください。",
                    },
                })}
                error={errors.email}
            />
            <InputBox 
                label="電話番号(ハイフン無し)"
                placeholder="09000000000"
                required={true}
                register={register("phone", {
                    required: "電話番号は必須です。",
                    pattern: {
                        value: /^[0-9]{10,11}$/,
                        message: "正しい電話番号を入力してください。",
                    },
                })}
                error={errors.phone}
            />
            <InputBoxText 
                label="お問い合わせ内容"
                placeholder="ご質問・ご要望をご記入ください"
                required={true}
                register={register("message", { required: "お問い合わせ内容は必須です。" })}
                error={errors.message}
            />
            <div css = {btnContainer}>
                <button type="submit" css={submitBtnCss}>入力した内容を確認する</button>
            </div>

        </form>
    );
};

const wrapper = css`
    max-width: 1000px;
    width: 50%;
    margin: 0 auto;
    @media screen and (max-width: 652px){
        width: 90%;
    }
`;

const submitBtnCss = css`
    width: 250px;
    min-width: 160px;
    height: 50px;
    border-radius: 10px;
    background: #D9D9D9;
    font-size: 13px;
    `;

const btnContainer = css`
    width: 100%;
    padding: 30px 0;
    display: flex;
    justify-content: center;
`
export default InputArea;
