import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from 'react-router-dom'

interface ConfirmProps {
    formData: { [key: string]: any },
    handleBack: any
    handleFinalSubmit: any
}


const keyToLabelMap: { [key: string]: string } = {
    name: "名前",
    company: "会社名",
    email: "メールアドレス",
    phone: "電話番号",
    message: "お問い合わせ内容",
};




const Confirm = ({
    formData,
    handleBack,
    handleFinalSubmit
} : ConfirmProps) => {
    console.log(formData);
    return (
        <div css = {bigContainer}>
            <div css={ConfirmContainer}>
                {Object.entries(formData).map(([key, value]) => (
                    <div css={wapper} key={key}>
                        <div css={flex}>
                            <label css={TitleCss}>{keyToLabelMap[key]}</label>
                        </div>
                        <div  css={inputCss}>{value} </div>
                    </div>
                ))}
            </div>
            <div css = {Container}>
                <button onClick={handleFinalSubmit} css={submitBtnCss}><p css={inputConfirm}>入力した内容で送信する</p><div css={rightTryangle}></div></button>
                <p css ={privacyMessage}><Link to={'/privacy'} target="_blank" >プライバシーポリシー</Link>をお読みの上、同意して送信してください。</p>
                <button onClick={handleBack } css={backBtnCss}><div css={leftTryangle}></div><p css={backMessage}>入力画面に戻る</p></button>
            </div>
            
        </div>
    );
}

const wapper = css`
    max-width: 1000px;
    display: flex;
    margin: 0 auto;
    @media screen and (max-width: 652px){
        width: 100%;
        flex-direction: column;
        gap: 12px;
        padding: 20px 0;
    }
`;
const flex = css`
    display: flex;
    width: 40%;
    align-items: flex-start;
    padding: 36px;
    @media screen and (max-width: 1000px){
        padding: 30px;
    }
    @media screen and (max-width: 652px){
        padding: 0;
        width: 100%;
    }
`;
const inputCss = css`
        width: 70%;
        font-size: 16px;
        color: #F6F6F6;
        align-items: flex-start;
        display: flex;
        border-left: 1px solid #F6F6F6;
        padding: 36px 60px;
        @media screen and (max-width: 1000px){

            padding: 30px;
        }
        @media screen and (max-width:652px){
            border: none;
            padding: 0;
        }
`;

const TitleCss = css`
    color: #F6F6F6;
    @media screen and (max-width: 1000px){
        font-size: 14px;
    }
    @media screen and (max-width:652px){
        font-size: 13px;
    }
`;



const inputConfirm = css`
    margin: 20px;
    @media screen and (max-width:652px){
        margin: 10px;
    }
`

const backMessage = css`
    margin: 15px;
`

const submitBtnCss = css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 50px;
    border-radius: 15px;
    background: #D9D9D9;
    margin: 20px auto;
    @media screen and (max-width: 1000px){
    }
    @media screen and (max-width:652px){
        margin: 0px;
    }
    `;

const backBtnCss = css`
        display: flex;
        align-items: center;
        justify-content: center;
        background: #212121;
        border: none;
        color: #F6F6F6;
        padding: 42px 0;
        @media screen and (max-width:652px){
            padding: 0px;
        }
`

const leftTryangle = css`
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 10px solid #F6F6F6;
    border-bottom: 5px solid transparent;
`

const rightTryangle = css`
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 10px solid #212121;
    border-bottom: 5px solid transparent;
`

const Container = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width:652px){
        padding: 0;
    }
`

const ConfirmContainer = css`
    margin: 60px 0;
    @media screen and (max-width:652px){
        margin: 30px 0;
    }
`

const bigContainer = css`
    width: 80%;
    margin: 0 auto;
    @media screen and (max-width:652px){
        width: 90%;
    }
`

const privacyMessage = css`
    color: #FFF;
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media screen and (max-width: 652px) {
        font-size: 10px;
        padding: 20px;
    } 
`
export default Confirm