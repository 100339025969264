import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PricacyTitle from '../features/privacypolicy/PricacyTitle';
import PrivacyMessage from '../features/privacypolicy/PrivacyMessage';
import PrivacyPolicyContent from '../features/privacypolicy/PrivacyPolicyContent';

const PrivacyPolicy = () => {

    const Container = css`
        width: 90%;
        margin: 0 auto 100px;
    `
    
    const ListItem = css`
        list-style-type: disc;
        padding: 0 10px;
    `

    const Address = css`
        margin: 0;
    `

  return (
    <div css={Container}>
        <PricacyTitle />
        <PrivacyMessage />
        <PrivacyPolicyContent 
            policyTitle='1.個人情報'
            policyContent='「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、電話番号、メールアドレス、その他の記述等により特定の個人を識別できる情報を指します。'
        />
        <PrivacyPolicyContent 
            policyTitle='2.個人情報の収集'
            policyContent='当チームは、ユーザーが問い合わせをする際に、本名、メールアドレス、電話番号、およびお問い合わせ内容などの個人情報をお尋ねすることがあります。また、ユーザーと提携先などとの間でなされた取引記録や決済に関する情報を、当チームの提携先から収集することがあります。'
        />
        <PrivacyPolicyContent policyTitle="3. 個人情報の収集・利用する目的" policyContent="当チームが個人情報を収集・利用する目的は、以下のとおりです。">
            <li css = {ListItem}>ユーザーからのお問い合わせに対応するため（本人確認を含む）</li>
            <li css = {ListItem}>メンテナンス、重要なお知らせなど必要に応じたご連絡のため</li>
            <li css = {ListItem}>利用規約に違反したユーザーや不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため</li>
            <li css = {ListItem}>上記の利用目的に付随する目的</li>
        </PrivacyPolicyContent>
        <PrivacyPolicyContent 
            policyTitle='4.利用目的の変更'
            policyContent='当チームは、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。利用目的の変更を行った場合には、変更後の目的について、当社所定の方法によりユーザーに通知し、または本ウェブサイト上に公表するものとします。'
        />
        <PrivacyPolicyContent 
            policyTitle='5.個人情報の第三者提供'
            policyContent='当チームは、法令の定めまたは行政当局の通達・指導がある場合を除き、取得した個人情報をご本人の同意なしに第三者へ提供することはございません。'
        />
        <PrivacyPolicyContent 
            policyTitle='6.個人情報の開示などについて'
            policyContent='当チームは、本人ご自身の開示対象個人情報について利用目的の通知・開示、内容の訂正・追加または削除、利用停止などの要請があった場合は、遅滞なくその求めに応じます。'
        />
        <PrivacyPolicyContent 
            policyTitle='7.お問い合わせ窓口'
            policyContent='本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。'
        >
        <div css={Address}>E-mail：contact@movken.jp</div>
        </PrivacyPolicyContent>
    </div>
  )
}

export default PrivacyPolicy