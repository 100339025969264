import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Logo from '../../asset/img/logo.png'
import TopButton from './TopButton';

const Successed = () => {

    const Container = css`
        position: relative;
        top: 100px;
        text-align: center;
        gap: 50px;
    `

    const Font = css`
        color: #FFF;
        font-family: "Noto Sans JP";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 36px */
    `

    const Message = css`
        padding-top: 70px;
    `

    const imgCss = css`
        width: 20%;
    `


  return (
    <div css={Container}>
        <img src={Logo}  css = {imgCss} alt=''/>
        <div css ={Message}>
            <p css = {Font}>送信完了いたしました<br />内容を確認後、ご連絡させていただきます。</p>
        </div>
        <TopButton />
    </div>
  )
}

export default Successed