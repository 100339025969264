import React, { useEffect, useRef } from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import topImg from '../asset/top-movie.webm';
import movkenLogo from '../asset/img/mov-top-logo.png';

const Home = () => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    
    // マウント時にスクロールを禁止
    useEffect(() => {
        document.body.style.overflow = "hidden";
    
        // アンマウント時に元に戻す
        return () => {
          document.body.style.overflow = "";
        };
      }, []);


    

  const wapper = css`
    width: 100%;
    margin: 0 auto;
    color: #FFF;
    overflow: hidden;
    height: calc(100vh - 90px);
    @media screen and (max-width: 1000px){
        margin-top: 60px;
        height: calc(100dvh - 60px);
    }
    overflow: hidden;
    background: #000;
  `


const videoCss = css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.1);
    overflow: hidden;
    background: #000;

    /* opacity: 0.9; */
    /* filter: blur(5px); */
`;

const logoContainer = css`
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10; 
    pointer-events: none;
    user-select: none;
`;
const logoCss = css`
    margin-top: 60px;
    @media screen and (min-width: 1000px) {
        margin-top: 90px;
    }
    opacity: 0.9;
    max-width: 200px;
    height: auto;
    @media screen and (max-width: 1000px) {
        /* max-width: 150px; */
    }

    /* スマホの場合 */
    @media screen and (max-width: 652px) {
        max-width: 140px;
    }
`;

useEffect(() => {
    const handleVisibilityChange = () => {
        console.log("visibilitychange_1");
        if (document.visibilityState === "visible") {
            console.log("visibilitychange_2");
            videoRef.current?.play();
        }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
        document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
}, []);

  return (

    <div css = {wapper}>
        <div css={logoContainer}>
            <img src={movkenLogo} alt="Logo" css={logoCss}/>
        </div>
        <video css={videoCss} 
            autoPlay
            loop
            muted
            playsInline
            ref={videoRef}
        >
            <source src={topImg} type="video/webm" />
        </video>
    </div>
  )
}

export default Home