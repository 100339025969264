import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import parse from 'html-react-parser';
import InfoContainer from '../features/works/detail/InfoContainer';
import MediaContainer from '../features/works/detail/MediaContainer';


interface Work {
    id: number;
    slug: string;
    title: string;
    en_title: string;
    thumbnail: string;
    tag: string[];
    body: string;
    credit: string;
    media: MediaItem[];
    createdAt: string;
    updatedAt: string;
}
interface MediaItem {
    type: string; // "vimeo" または "image" を想定
    url?: string; // Vimeo の場合の URL
    image?: string; // 画像の場合のパス
  }

const WorkDetail = () => {
    const { slug } = useParams<{ slug: string }>();

    const [work, setWork] = useState<Work | null>(null); // 初期値を null に設定
    const [mainMedia, setMainMedia] = useState<MediaItem | null>(null); // メインメディアの状態管理

    const imgBaseUrl = process.env.REACT_APP_IMG_URL!;


    useEffect(() => {
        fetch('https://panel.movken.jp/web/works/' + slug)
        .then(response => response.json())
        .then(data => {
            console.log('https://panel.movken.jp/web/works/' + slug);
            console.log(data)
            setWork(data)
            console.log(data);
            setMainMedia(data.media[0]);
        })
    }, []);


    if (!work) {
        // データがまだ取得されていない場合のローディング表示
        return <div></div>;
    }



    return (
        <div css={wapper}>
            <img src={imgBaseUrl + work.thumbnail}
            css={headerImgCss}
            alt="" />
            <div css={container}>

                {/* タグ タイトル Enタイトル */}
                <InfoContainer work={work} />

                {/* メディアコンテナ */}
                <MediaContainer 
                    work={work} 
                    mainMedia={mainMedia} 
                    imgBaseUrl={imgBaseUrl}
                    setMainMedia={setMainMedia}
                />

                <div css={hr} />

                <div css={bodyContainer}>
                    <div css={bodyContents}>{work.body ? parse(work.body) : <div />}</div>
                    <div css={creditContents}>
                    {work.credit ? parse(work.credit) : <div />}
                    </div>
                    
                </div>

            </div>

            <Link 
                to="/works"
                css={backBtnLink}
            >
                <div css={backBtn}> {"<  BACK"} </div>
            </Link>
            
        </div>
    );
};

export default WorkDetail;


const wapper = css`
    color: #FFFFFF;
    @media screen and (max-width: 1000px){
        padding-top: 60px;
    }
        
`;


const headerImgCss = css`
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    `; 

const container = css`
    width: 80%;
    margin: 0 auto;
    
    @media (max-width: 768px) {
        width: 90%;
    }
`;

const hr = css`
    margin: 40px 0;
    border: 0.5px solid #F6F6F6;
`;



const bodyContainer = css`
    /* width: 80%; */
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
`;
const bodyContents = css`
    font-family: "Noto Sans JP";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
`;

const creditContents = css`
    margin: 40px 0;
    color: #969696;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 150%; /* 24px */
    h1, h2, h3, h4, h5, h6, p, a, li, span, div, input, textarea, button, label, select, option, optgroup, fieldset, legend {
        font-family: "Be Vietnam Pro";
    }

`;


const backBtnLink = css`
    text-align: center;
    margin: 20px 0;
    cursor: pointer;
    text-decoration: none;
`;

const backBtn = css`
    color: #FFFFFF;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    margin: 100px;
    font-family: "Be Vietnam Pro";
    font-weight: 350;
    letter-spacing: 0.1em;
`;