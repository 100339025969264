/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

type PrivacyPolicyProps = {
    policyTitle?: string;
    policyContent?: string;
    children?: React.ReactNode; // `children` を追加
};

const PrivacyPolicyContent: React.FC<PrivacyPolicyProps> = ({
    policyTitle = "#####",
    policyContent = "######",
    children
}) => {

    const Container = css`
        width: 75%;
        @media screen and (max-width: 850px) {
            width: 95%;
        }
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 0;
    `
    const Title = css`
        width: 100%;
        color: var(--white, #F6F6F6);
        font-family: "Noto Sans JP";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 200%; /* 32px */
    `

    const Content = css`
        width: 95%;
        color: var(--white, #F6F6F6);
        font-family: "Noto Sans JP";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 200%; /* 32px */
        @media screen and (max-width: 850px) {
            font-size: 12px;
        }
    `
    
  return (
    <div css={Container}>
        <div css = {Title}>{policyTitle}</div>
        <div css = {Content}>{policyContent}</div>
        <div css = {Content}>{children}</div>
    </div>
  )
}

export default PrivacyPolicyContent