import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import movkenLogo from '../asset/img/logo.png';

  const wapper = css`
        width: 100%;
        margin: 0 auto;
        color: #FFF;
        overflow: hidden;
        height: calc(100vh - 90px);
        @media screen and (max-width: 1000px){
            margin-top: 60px;
            height: calc(100dvh - 60px);
        }
        overflow: hidden;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
  `
    const movkenLogoCss = css`
        width: 250px;

        @media screen and (max-width: 1000px){
            width: 180px;
        }
    `;

    const title = css`
        margin-top: 40px;
        font-size: 54px;
        font-weight: 400;
        font-family: "Be Vietnam Pro";
    `;
    const subTitle = css`
        font-size: 40px;
        font-weight: 400;
        font-family: "Be Vietnam Pro";
    `;

const NotFound = () => {
  return (
    <div css={wapper}>
        <img src={movkenLogo} alt="mov研 ロゴ" css={movkenLogoCss}/>
        <h1 css={title}>404</h1>
        <h1 css={subTitle}> Not Found</h1>
    </div>
  )
}

export default NotFound