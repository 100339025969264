import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Subtitle from './Subtitle';

export const  Vision = () => {

    const brCss1 = css`
        display: none;
        @media screen and (max-width:652px){
            display: block;
        }
    `
    const brCss2 = css`
        display: none;
        @media screen and (max-width:1415px){
            display: block;
        }
    `

    const japTextCss = css`
        font-size: 24px; 
        @media screen and (max-width:1000px){
            font-size: 15px;
        }
        @media screen and (max-width:652px){
            font-size: 13px;
        }
    `

    const engTextCss = css`
        font-family: "Be Vietnam Pro";
        font-size: 39px;
        line-height: normal;
        @media screen and (max-width:1000px){
            font-size: 34px;
            line-height: 40px;
        }
        @media screen and (max-width:843px){
            font-size: 27px;
            line-height: 40px;
        }
        @media screen and (max-width:447px){
            font-size: 21px;
            line-height: 30px;
        }
    `

    const textWrapper = css`
        width: 80%;
        margin: 0 auto;
        color: #FFF;
        font-style: normal;
        font-weight: 300;
        line-height: 40px;
        letter-spacing: -0.84px;
        text-align: center;
        padding: 80px 0;
        @media screen and (max-width:1000px){
            padding: 60px 0;
        }
        @media screen and (max-width:652px){
            padding: 30px 0;
        }
    `

    const grayText = css`
        color: #969696;
        font-family: "Noto Sans JP";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        @media screen and (max-width:1000px){
            font-size: 16px;
            line-height: 30px;
        }
        @media screen and (max-width:843px){
            font-size: 14px;
            line-height: 20px;
        }
        @media screen and (max-width:447px){
            font-size: 12px;
            line-height: 20px;
        }
    `

  return (
    <div> 
        <Subtitle
            stringValue='Concept'
        />
        <div css = {textWrapper}>
            <p css= {engTextCss}>Content that <br css = {brCss1} /> you will want to share <br css = {brCss2}/> with someone.</p>
            <p css = {japTextCss}>誰かに共有したくなるコンテンツを。</p>
        </div>
        <p css={grayText}>良いコンテンツやおもしろいコンテンツは、一人でとどめるのではなく ” 誰かに共有したくなる ”。<br />mov研は誰かに共有したくなるようなコンテンツを目指して制作を行う。</p>
    </div>
  )
}
